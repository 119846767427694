export default {
  start_date: 'Arrivée',
  end_date: 'Départ',
  name: 'Prénom',
  surname: 'Nom',
  phone: 'Téléphone',
  email: 'Email',
  country: 'Nationalité',
  language: 'Langue',
  notes: 'Notes / Demandes supplémentaires',
  privacy: 'JE CONFIRME AVOIR LU, COMPRIS ET ACCEPTÉ LA POLITIQUE DE CONFIDENTIALITÉ',
  adults: 'Adultes',
  children: 'Enfants',
  submit: 'Envoyer',
  request: 'Demander un devis',
  ok: 'OK',
  cancel: 'Annuler',
  choose_from_list: 'Choisir une valeur dans la liste',
  room: 'Chambre',
  add_room: 'Ajouter une chambre',
  fix_entry_data: 'Erreurs d\'insertion',
}
