<template>
  <div id="app">
    <div class="tw-mx-auto tw-bg-white tw-px-4 tw-py-2" :class="containerClass">
      <form @submit.prevent>
        <div class="tw-grid">
          <t-dropdown class="tw-col-span-12" id="dates-dropdown">
            <template
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <div class="tw-grid tw-grid-cols-2"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <div id="cb-start-date-box">
                  <div class="tw-uppercase tw-text-sm">
                    {{ $lang('start_date') }}
                  </div>
                  <div class="tw-flex tw-flex-row tw-justify-start">
                    <div id="cb-start-date-number" class="tw-basis-1/4 tw-text-4xl tw-mr-2 tw-my-1">
                      {{ form.start_date.format('DD') }}    
                    </div>
                    <div class="tw-basis-3/4 tw-flex tw-flex-col tw-my-1">
                      <div class="tw-text-sm tw-font-bold">
                        {{ form.start_date.locale(form.language).format('MMMM') }}
                        {{ form.start_date.format('YYYY') }}
                      </div>
                      <div class="tw-text-xs">
                        {{ form.start_date.locale(form.language).format('dddd') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="cb-end-date-box">
                  <div class="tw-uppercase tw-text-sm">
                    {{ $lang('end_date') }}
                  </div>
                  <div class="tw-flex tw-flex-row tw-justify-start">
                    <div id="cb-start-date-number" class="tw-basis-1/4 tw-text-4xl tw-mr-2 tw-my-1">
                      {{ form.end_date.format('DD') }}    
                    </div>
                    <div class="tw-basis-3/4 tw-flex tw-flex-col tw-my-1">
                      <div class="tw-text-sm tw-font-bold">
                        {{ form.end_date.locale(form.language).format('MMMM') }}
                        {{ form.end_date.format('YYYY') }}
                      </div>
                      <div class="tw-text-xs">
                        {{ form.end_date.locale(form.language).format('dddd') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div slot-scope="{ hide, blurHandler }">
              <t-datepicker
                v-model="range"
                :inline="true"
                :range="true"
                :months-per-view="2"
                :lang="form.language"
                :weekStart="1"
                :minDate="today"
                @blur="blurHandler"
              />
            </div>
          </t-dropdown>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-2">
          <t-input-group
            :label="$lang('name')"
          >
            <t-input
              v-model="form.name"
              type="text"
              :variant="{
                error: !!validation.errors.name
              }"
              required
            />
          </t-input-group>
          <t-input-group
            :label="$lang('surname')"
          >
            <t-input
              v-model="form.surname"
              type="text"
              :variant="{
                error: !!validation.errors.surname
              }"
              required
            />
          </t-input-group>
          <t-input-group
            :label="$lang('email')"
          >
            <t-input
              v-model="form.email"
              type="text"
              :variant="{
                error: !!validation.errors.email
              }"
              required
            />
          </t-input-group>
          <t-input-group
            :label="$lang('phone')"
          >
            <t-input
              v-model="form.phone"
              type="text"
              :variant="{
                error: !!validation.errors.phone
              }"
              placeholder="+XX XXXXXXXXXX"
            />
          </t-input-group>
        </div>
        <div v-if="withCountry" class="tw-py-4">
          <t-input-group
            :label="$lang('country')"
          >
            <t-select
              v-model="form.country"
              :placeholder="$lang('choose_from_list')"
              :options="iso.countries"
              textAttribute="name"
              valueAttribute="code"
              :variant="{
                error: !!validation.errors.country
              }"
            />
          </t-input-group>
        </div>
        <div class="tw-py-4">
          <t-input-group
            :label="$lang('notes')"
          >
            <t-textarea
              v-model="form.notes"
            />
          </t-input-group>
        </div>
        <div v-for="(item, index) in form.request" class="tw-grid tw-grid-cols-3 tw-gap-2" :key="index">
          <div>
            <div class="tw-uppercase tw-text-sm tw-guests-label">
              {{ $lang('room') }}
            </div>
            <div id="cb-guests-number" class="tw-flex tw-flex-row tw-basis-4/4 tw-text-3xl tw-mr-2 tw-my-1">
              <button @click="removeRoom(index)">
                <mdicon name="delete" :width="20" :height="20" class="cb-primary-text" />
              </button>
              <span class="tw-w-10 tw-h-10 tw-text-center tw-flex tw-items-center tw-justify-center">{{index + 1}}</span>
            </div>
          </div>
          <div>
            <div class="tw-uppercase tw-text-sm tw-guests-label">
              {{ $lang('adults') }}
            </div>
            <div id="cb-guests-number" class="tw-flex tw-flex-row tw-basis-4/4 tw-text-3xl tw-mr-2 tw-my-1">
              <button @click="subAdults(index)">
                <mdicon name="minus-circle" :width="20" :height="20" class="cb-primary-text" />
              </button>
              <span class="tw-w-10 tw-h-10 tw-text-center tw-flex tw-items-center tw-justify-center">{{item.adults}}</span>
              <button @click="addAdults(index)">
                <mdicon name="plus-circle" :width="20" :height="20" class="cb-primary-text" />
              </button>
            </div>
          </div>
          <div>
            <div class="tw-uppercase tw-text-sm tw-guests-label">
              {{ $lang('children') }}
            </div>
            <div id="cb-guests-number" class="tw-flex tw-flex-row tw-basis-4/4 tw-text-3xl tw-mr-2 tw-my-1">
              <button @click="subChildren(index)">
                <mdicon name="minus-circle" :width="20" :height="20" class="cb-primary-text" />
              </button>
              <span class="tw-w-10 tw-h-10 tw-text-center tw-flex tw-items-center tw-justify-center">{{item.children}}</span>
              <button @click="addChildren(index)">
                <mdicon name="plus-circle" :width="20" :height="20" class="cb-primary-text" />
              </button>
            </div>
          </div>
        </div>
        <div>
          <t-button class="tw-h-full tw-w-full" @click="addRoom">
            <span class="tw-flex tw-justify-center tw-uppercase">
              <mdicon name="plus" />
              {{ $lang('add_room') }}
            </span>
          </t-button>
        </div>
        <div class="tw-my-4" :class="!!validation.errors.privacy ? 'tw-border-solid tw-border tw-border-red-300 tw-bg-red-100 tw-rounded' : ''">
          <label class="tw-flex tw-items-center tw-ml-2">
            <t-checkbox v-model="form.privacy" name="privacy" class="tw-mr-2" :variant="{
                error: !!validation.errors.privacy
              }"
              :label="$lang('privacy')"
            />
            <span class="ml-2 text-sm">
              <a href="https://www.ciaobooking.com/privacy-policy" target="_blank">
                {{ $lang('privacy') }}
              </a>
            </span>
          </label>
        </div>
        <t-alert v-if="validation.error" variant="error" show class="tw-my-4">
          {{ validation.message }}
        </t-alert>
        <div>
          <t-button
            id="cb-submit-button"
            class="tw-h-full tw-w-full tw-uppercase"
            @click="submit"
          >
            {{ alternativeSubmit ? $lang('request') : $lang('submit') }}
          </t-button>
        </div>
        
      </form>
      <component :is="'style'">
        :root {
          --primary: {{ color }};
          --blue-600: {{ color }};
          --blue-200: {{ color.slice(0, 7) }}60;
          --primary-light: {{ color.slice(0, 7) }}60;
          --font: {{ fontFamily }};
        }
      </component>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import iso from '@/mixins/iso.js';

export default {
  name: "App",
  props: {
    color: {
      type: String,
      default: "#424242"
    },
    newPage: {
      type: Boolean,
      default: false
    },
    maxGuests: {
      type: Number,
      default: 999
    },
    withShadow: {
      type: Boolean,
      default: false,
    },
    withBoxBorder: {
      type: Boolean,
      default: false,
    },
    withCountry: {
      type: Boolean,
      default: false,
    },
    expandedOccupation: {
      type: Boolean,
      default: false,
    },
    alternativeSubmit: {
      type: Boolean,
      default: false,
    },
    fontFamily: {
      type: String,
      default: 'Montserrat'
    },
    company: {
      type: String,
      default: null,
    },
    property: {
      type: String,
      default: null,
    },
    referral: {
      type: String,
      default: null
    },
    redirect: {
      type: String,
      default: 'https://www.google.com'
    }
  },
  data() {
    return {
      form: {
        company_id: null,
        property_id: null,
        start_date: null,
        end_date: null,
        name: null,
        surname: null,
        phone: null,
        email: null,
        country: null,
        language: 'en',
        notes: null,
        privacy: false,
        request: [{
          adults: 2,
          children: 0,
        }],
        referral: null
      },
      range: [],
      masks: {
        input: 'DD/MM/YYYY',
      },
      today: new Date(),
      isDatesOpen: false,
      isGuestsOpen: false,
      today: dayjs().format('YYYY-MM-DD'),
      iso: iso,
      validation: {
        error: false,
        errors: [],
      }
    };
  },
  beforeMount() {
    this.form.start_date = dayjs();
    this.form.end_date = dayjs().add(1, 'day');
    this.form.language = navigator.language.slice(0, 2);
    this.form.referral = this.referral;
    this.form.company_id = this.company;
    this.form.property_id = this.property;
  },
  methods: {
    submit() {
      const data = {
        ...this.form
      };

      data.start_date = dayjs(data.start_date).format('YYYY-MM-DD');
      data.end_date = dayjs(data.end_date).format('YYYY-MM-DD');
      const url = 'https://api.ciaobooking.com/api/3.0/quote-requests/webform';

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(response => {
        if (response.status !== 200) {
          response.json().then(data => {
            this.validation.error = true;
            this.validation.message = data.message;
            this.validation.errors = data.errors;

            let allMessages = '';

            for (const key in this.validation.errors) {
                if (this.validation.errors.hasOwnProperty(key)) {
                  this.validation.errors[key].forEach(message => {
                      allMessages += message + '\n';
                  });
                }
            }

            this.$dialog.confirm({
              title: this.$lang('fix_data_entry'),
              text: allMessages,
              icon: 'danger',
            });
          });
          return;
        }
        window.location.assign(this.redirect);
      })
      .catch((error) => {
          alert(error)
          console.error('Error:', error);
      });
    },
    // openDates() {
    //   const elements = document.querySelectorAll('body *:not(#dates-modal, ciaobooking-widget, #app)');
    //   elements.forEach(el => {
    //     el.classList.add('tw-dialog-overlay-force');
    //   });
    //   this.isDatesOpen = true;
    // },
    // openGuests() {
    //   const elements = document.querySelectorAll('body *:not(#guest-modal, ciaobooking-widget, #app)');
    //   elements.forEach(el => {
    //     el.classList.add('tw-dialog-overlay-force');
    //   });
    //   this.isGuestsOpen = true;
    // },
    // closeDialog() {
    //   const elements = document.querySelectorAll('.tw-dialog-overlay-force');
    //   elements.forEach(el => {
    //     el.classList.remove('tw-dialog-overlay-force');
    //   });
    // },
    addAdults(index) {
      this.form.request[index].adults +=1;
    },
    subAdults(index) {
      if (this.form.request[index].adults === 0) {
        return;
      }

      this.form.request[index].adults -=1;
    },
    addChildren(index) {
      this.form.request[index].children +=1;
    },
    subChildren(index) {
      if (this.form.request[index].children == 0) {
        return;
      }
      
      this.form.request[index].children -=1;
    },
    addRoom() {
      this.form.request.push({
        adults: 2,
        children: 0,
      });
    },
    removeRoom(index) {
      this.form.request.splice(index, 1);
    },
    blurHandler(data) {
      console.log(data);
    }
    // async getDestinations() {
    //   if (!this.withDestinations || !this.bookingNetworkId) {
    //     return;
    //   }

    //   let response = await fetch(`${apiUrl}/booking-network-destinations/${this.bookingNetworkId}`);

    //   response = await response.json();
    //   this.destinations = response.data;
    // },
  },
  computed: {
    layout() {
      return this.$screens(
        {
          default: {
            columns: 1,
            rows: 1,
            isExpanded: true,
          },
          lg: {
            columns: 2,
            rows: 1,
            isExpanded: false,
          },
        },
      );
    },
    containerClass() {
      let result = '';

      if (this.withShadow) {
        result += ' shadow-md';
      }

      return result;
    },
  },
  watch: {
    range(val) {
      if (!val) {
        return;
      }

      if (!val || val.length !== 2) {
        return;
      }

      this.form.start_date = dayjs(val[0]);
      this.form.end_date = dayjs(val[1]);
    },
    isDatesOpen(newVal) {
      if (!newVal) {
        this.closeDialog();
      }
    },
  }
};
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
#app {
  all: initial;
  font-family: var(--font);
}
#cb-start-date-number {
  color: var(--primary);
}
#cb-end-date-number {
  color: var(--primary);
}
#cb-guests-number {
  color: var(--primary);
}
#cb-submit-button {
  background-color: var(--primary);
}
#cb-submit-button:hover {
  background-color: var(--primary-light);
}
.cb-primary-bg {
  background-color: var(--primary) !important;
}
.cb-primary-text {
  color: var(--primary) !important;
}
.box-border {
  border: 1px solid var(--primary) !important;
  padding: 3px;
  margin: 3px;
  border-radius: 4px;
}
div#dates-dropdown input {
    display: none;
}
.tw-dialog-overlay-force {
	z-index: unset !important;
}
.filter-blur-force {
	backdrop-filter: blur(10px);
}
</style>