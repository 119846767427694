export default {
  start_date: 'Arrival',
  end_date: 'Departure',
  name: 'Name',
  surname: 'Surname',
  phone: 'Phone',
  email: 'Email',
  country: 'Nationality',
  language: 'Language',
  notes: 'Notes / Additional requests',
  privacy: 'I CONFIRM THAT I HAVE READ, UNDERSTOOD AND ACCEPTED THE PRIVACY POLICY',
  adults: 'Adults',
  children: 'Children',
  submit: 'Submit',
  request: 'Request a quote',
  ok: 'OK',
  cancel: 'Cancel',
  choose_from_list: 'Choose a value from the list',
  room: 'Room',
  add_room: 'Add room',
  fix_entry_data: 'Errors in data',
}
