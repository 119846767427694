export default {
  start_date: 'Llegada',
  end_date: 'Salida',
  name: 'Nombre',
  surname: 'Apellido',
  phone: 'Teléfono',
  email: 'Correo Electrónico',
  country: 'Nacionalidad',
  language: 'Idioma',
  notes: 'Notas / Solicitudes Adicionales',
  privacy: 'CONFIRMO QUE HE LEÍDO, ENTENDIDO Y ACEPTADO LA POLÍTICA DE PRIVACIDAD',
  adults: 'Adultos',
  children: 'Niños',
  submit: 'Enviar',
  request: 'Solicitar un presupuesto',
  ok: 'OK',
  cancel: 'Cancelar',
  choose_from_list: 'Elija un valor de la lista',
  room: 'Habitación',
  add_room: 'Añadir habitación',
  fix_entry_data: 'Errores de entrada',
}
