export default {
  start_date: 'Ankunft',
  end_date: 'Abreise',
  name: 'Vorname',
  surname: 'Nachname',
  phone: 'Telefon',
  email: 'E-Mail',
  country: 'Nationalität',
  language: 'Sprache',
  notes: 'Anmerkungen / Zusätzliche Anfragen',
  privacy: 'ICH BESTÄTIGE, DIE DATENSCHUTZERKLÄRUNG GELESEN, VERSTANDEN UND AKZEPTIERT ZU HABEN',
  adults: 'Erwachsene',
  children: 'Kinder',
  submit: 'Absenden',
  request: 'Ein Angebot anfordern',
  ok: 'OK',
  cancel: 'Abbrechen',
  choose_from_list: 'Wählen Sie einen Wert aus der Liste',
  room: 'Zimmer',
  add_room: 'Zimmer hinzufügen',
  fix_entry_data: 'Eingabefehler',
}