export default {
  start_date: 'Arrivo',
  end_date: 'Partenza',
  name: 'Nome',
  surname: 'Cognome',
  phone: 'Telefono',
  email: 'Email',
  country: 'Nazionalità',
  language: 'Lingua',
  notes: 'Note / Richieste aggiuntive',
  privacy: 'CONFERMO DI AVER LETTO, COMPRESO ED ACCETTATO L\'INFORMATIVA SULLA PRIVACY',
  adults: 'Adulti',
  children: 'Bambini',
  submit: 'Invia',
  request: 'Richiedi un preventivo',
  ok: 'OK',
  cancel: 'Annulla',
  choose_from_list: 'Scegli un valore dalla lista',
  room: 'Camera',
  add_room: 'Aggiungi camera',
  fix_entry_data: 'Errori di inserimento',
}