import it from '@/i18n/it';
import en from '@/i18n/en';
import fr from '@/i18n/fr';
import es from '@/i18n/es';
import de from '@/i18n/de';

export default {
  data() {
    return {
      translations: {
        it: it,
        en: en,
        de: de,
        fr: fr,
        es: es,
      },
      language: 'en',
      fallback: 'en',
    }
  },
  beforeMount() {
    this.language = navigator.language.slice(0, 2);
  },
  methods: {
    /**
     * Get translation
     *
     * @param key
     * @param params
     * @returns {*}
     */
    $lang(key, params) {
      let translations = this.translations;

      if (!translations) {
        return "";
      }

      const keys = key.split(".");

      keys.map(key => {
        if (!translations[this.language] || typeof translations[this.language][key] === "undefined") {
          translations = translations[this.fallback][key];

          return false;
        }

        translations = translations[this.language][key];
      });

      if (typeof params !== 'object') {
        return translations;
      }

      for (let i in params) {
        translations = translations.replace(`:${i}`, params[i]);
      }

      return translations;
    }
  },
};
